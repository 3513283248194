<template>
  <Card class="container-body">
    <!-- Card -->
    <div>
      <div class="flex items-center justify-between">
        <p class="text-lightPurple-2 font-semibold">My Cards</p>
        <div class="controls flex items-center">
          <div class="button-round p-1 mr-3" @click="previous">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1624012023/customer/Vector_15_c0u4go.svg"
              alt="previous"
            />
          </div>
          <div class="button-round p-1" @click="next">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1624012221/customer/Vector_15_1_pkuwqn.svg"
              alt="next"
            />
          </div>
        </div>
      </div>

      <div class="wrapper mt-6" v-if="cardLoading" key="none">
        <Flickity :options="flickityOptions">
          <Skeleton
            height="9rem"
            width="18rem"
            v-for="i in 2"
            :key="i"
            class="mr-2"
          />
        </Flickity>
      </div>

      <div class="wrapper mt-6" v-else key="around">
        <AddCard
          v-if="userCards.length === 0"
          :startAddCard="initiateAddCard"
          @stopAddCard="initiateAddCard = false"
          @close="handleCardClose"
        >
          <template>
            <Flickity ref="flickity" :options="flickityOptions">
              <div class="carousel-cell add-card cursor-pointer">
                <div class="text-center" @click="initiateAddCard = true">
                  <p class="text-lightPurple-2 text-lg md:text-2xl">+</p>
                  <p class="font-semibold text-lightPurple-2">Add a Card</p>
                  <p class="text-xs text-lightPurple-2 mt-2">
                    Kindly make sure the card you intend to add is yours and
                    tied to your account
                  </p>
                </div>
              </div>
            </Flickity>
          </template>
        </AddCard>

        <Flickity v-else ref="flickity" :options="flickityOptions">
          <div
            @click="selectDefaultCard(card)"
            class="carousel-cell overflow-hidden first-card relative grid p-2"
            v-for="card in cardList"
            :key="card.id"
          >
            <!-- default card -->
            <div class="default-banner text-center" v-if="card.primary">
              <p>DEFAULT</p>
            </div>

            <div class="flex justify-between" v-if="card.cardType">
              <!-- visa -->
              <svg
                v-if="card.cardType.toLowerCase().trim() === 'visa'"
                xmlns="http://www.w3.org/2000/svg"
                width="53"
                height="17"
                fill="none"
              >
                <path
                  d="M27.226 5.435c-.03 2.368 2.11 3.69 3.723 4.475 1.657.806 2.213 1.323 2.207 2.044-.013 1.103-1.322 1.59-2.547 1.61-2.137.033-3.38-.578-4.368-1.04l-.77 3.603c.992.457 2.827.856 4.73.873 4.468 0 7.39-2.205 7.406-5.624.018-4.34-6.002-4.58-5.96-6.52.013-.588.575-1.215 1.805-1.375.608-.08 2.288-.142 4.193.735L38.393.73C37.37.357 36.052 0 34.413 0c-4.205 0-7.163 2.235-7.187 5.435ZM45.578.3c-.816 0-1.504.476-1.81 1.207l-6.382 15.237h4.464l.889-2.455h5.455l.515 2.455h3.935L49.211.3h-3.633Zm.624 4.442 1.289 6.175h-3.529l2.24-6.175ZM21.814.3l-3.52 16.444h4.255L26.066.3h-4.252ZM15.52.3l-4.428 11.192-1.79-9.516C9.091.914 8.261.3 7.34.3H.1L0 .778c1.486.322 3.174.842 4.197 1.399.626.34.805.637 1.01 1.445L8.6 16.744h4.495L19.988.3H15.52Z"
                  fill="url(#a)"
                />
                <defs>
                  <linearGradient
                    id="a"
                    x1="24.203"
                    y1="17.341"
                    x2="24.695"
                    y2="-.118"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#E3E3E3" />
                  </linearGradient>
                </defs>
              </svg>

              <!-- mastercard -->
              <svg
                v-if="card.cardType.toLowerCase().trim() === 'mastercard'"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="19"
                fill="none"
              >
                <path
                  d="M19.766 2.032h-8.413v14.936h8.413V2.032Z"
                  fill="#FF5F00"
                />
                <path
                  d="M11.886 9.5c0-3.035 1.442-5.726 3.659-7.468A9.63 9.63 0 0 0 9.615 0C4.3 0 0 4.249 0 9.5S4.3 19 9.615 19a9.63 9.63 0 0 0 5.93-2.032 9.445 9.445 0 0 1-3.66-7.468Z"
                  fill="#EB001B"
                />
                <path
                  d="M31.117 9.5c0 5.251-4.3 9.5-9.615 9.5a9.63 9.63 0 0 1-5.93-2.032 9.405 9.405 0 0 0 3.66-7.468c0-3.035-1.443-5.726-3.66-7.468A9.63 9.63 0 0 1 21.502 0c5.315 0 9.615 4.275 9.615 9.5Z"
                  fill="#F79E1B"
                />
              </svg>

              <!-- verve -->
              <svg
                v-if="card.cardType.toLowerCase().trim() === 'verve'"
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.23 12.115c0 6.691-5.423 12.115-12.114 12.115C5.424 24.23 0 18.806 0 12.115 0 5.425 5.424 0 12.116 0c6.69 0 12.115 5.424 12.115 12.115Z"
                  fill="#ED342B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.115 17.305C9.405 11.162 7.418 5.424 7.418 5.424H3.264S5.793 12.789 10.49 22h3.251c4.697-9.21 7.226-16.576 7.226-16.576h-4.155s-1.987 5.738-4.697 11.881Z"
                  fill="#FEFEFE"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M64.292 11.483c-2.349 0-2.53 2.53-2.53 2.53h5.058s-.18-2.53-2.528-2.53Zm5.599 5.058h-8.128s.18 2.71 3.793 2.71c1.806 0 3.612-.543 3.612-.543l.362 2.89s-1.806.723-4.335.723c-3.613 0-6.864-1.807-6.864-6.864 0-3.974 2.529-6.503 6.141-6.503 5.419 0 5.78 5.42 5.419 7.587Zm-27.639-4.268.56-2.983s-4.32-1.31-7.83 1.118v11.93h3.729v-9.693c1.49-1.118 3.541-.372 3.541-.372Zm-15.539-.79c-2.348 0-2.528 2.53-2.528 2.53h5.057s-.18-2.53-2.529-2.53Zm5.6 5.058h-8.128s.18 2.71 3.793 2.71c1.806 0 3.612-.543 3.612-.543l.362 2.89s-1.807.723-4.336.723c-3.612 0-6.864-1.807-6.864-6.864 0-3.974 2.53-6.503 6.142-6.503 5.419 0 5.78 5.42 5.419 7.587Zm19.037 1.238c-1.893-4.544-2.84-8.823-2.84-8.823h-3.784s1.892 7.308 5.11 13.363h3.027c3.218-6.055 5.111-13.361 5.111-13.361H54.19s-.946 4.277-2.84 8.82Z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div class="flex items-end h-full justify-between">
              <p class="text-sm md:text-base text-white font-semibold">
                {{ card.maskedPan }}
              </p>
              <p class="text-sm md:text-base text-white font-semibold">
                {{ card.expireMonth }}/{{ card.expireYear }}
              </p>
            </div>
          </div>
        </Flickity>

        <!-- to choose default card -->
        <div class="default-card-container flex items-center p-4 my-3">
          <svg-icon
            color="#0EA2F5"
            type="mdi"
            :path="svgPath.warning"
            class="mr-3"
          ></svg-icon>
          <p class="text-grey text-xs mr-3">
            Choose a default card for payment by clicking on the card.
          </p>
          <!-- <svg-icon class="cursor-pointer" color="#000000" type="mdi" :path="svgPath.close"></svg-icon> -->
        </div>
        <div v-if="userCards.length > 0" class="flex justify-end mt-4">
          <AddCard @close="handleCardClose" />
        </div>
      </div>
    </div>

    <BankAccounts @linkBank="handleLinkBank" :bankLoading="bankLoading" />

    <LargeModal
      :display="showAddBank"
      @close="showAddBank = false"
      padding="px-6 pt-6"
      background="white"
    >
      <AddBank @bankAdded="handleAddBankSuccess" @close="handleCloseAddbank" />
    </LargeModal>

    <CashoutAccount />

    <!-- modal to select default card -->
    <Modal
      :display="displaySelectedCardModal"
      @close="displaySelectedCardModal = false"
    >
      <p class="text-lg primary-text font-bold">
        Choose as default card for payments
      </p>

      <p class="text-grey text-sm mt-4">
        You are about to choose (**** {{ selectedCard.last4Digits }}) as the
        card that should be charged for payments. You can always change this
        anytime.
      </p>
      <Button
        width="w-full"
        text="Choose as default card"
        class="mt-6"
        :loading="defaultCardLoading"
        @click="setAsDefaultCard"
      />
      <p
        class="text-center mt-4 font-semibold text-brandPurple"
        @click="displaySelectedCardModal = false"
      >
        Close
      </p>
    </Modal>
  </Card>
</template>
  <script>
import { mapActions, mapState } from "vuex";
import { ADD_CARD } from "@/utils/api/userInfo";
import { SET_DEFAULT_CARD } from "@/utils/api/dashboard";
import Flickity from "vue-flickity";
import { Button, SecondaryButton } from "@/UI/Button";
import CashoutAccount from "./CashoutAccount";
import { LargeModal, Modal } from "@/UI/Modals";
import { mdiClose, mdiAlertCircleOutline } from "@mdi/js";
import AddBank from "@/components/Home/CreditQualification/Bank/index.vue";
import BankAccounts from "./BankAccounts/BankAccounts.vue";
export default {
  components: {
    Flickity,
    Button,
    SecondaryButton,
    AddCard: () => import("../../Verification/AddCard"),
    BankAccounts,
    CashoutAccount,
    LargeModal,
    Modal,
    AddBank,
  },
  props: {
    user: {
      type: Object,
      default: {},
      default: false,
    },
  },
  data: () => ({
    flickityOptions: {
      initialIndex: 0,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: "center",
    },
    showLinkCard: false,
    showLinkAccount: false,
    hideCarousel: true,
    source: "",
    bankLoading: false,
    cardLoading: false,
    card: {
      showCard: false,
      source: "",
    },
    transactionId: "",
    showAddBank: false,
    svgPath: {
      close: mdiClose,
      warning: mdiAlertCircleOutline,
    },
    selectedCard: {},
    displaySelectedCardModal: false,
    defaultCardLoading: false,
    initiateAddCard: false,
  }),
  computed: {
    ...mapState({
      userCards: (state) => state?.dashboard?.userCards,
      walletId: (state) => state?.auth?.user?.instaPayWalletId,
      userBanks: (state) => state?.userInfo?.userBanks,
      bvn: (state) => state?.auth?.user?.bvn,
    }),
    cardList() {
      const primaryCard = this.userCards.filter((card) => card.primary);
      const index = this.userCards.findIndex((card) => card.primary);
      let list = this.userCards;
      list.splice(index, 1);
      list.unshift(primaryCard[0]);
      return list;
    },
  },

  created() {
    if(this.userCards.length === 0){
      this.getUserCards();
    }
    if(this.userBanks.length === 0 ){
      this.getAllUserBanks();
    }
  },

  methods: {
    ...mapActions("dashboard", ["getCards", "linkBank", "verifyBank", 'getDashboardData']),
    ...mapActions("userInfo", ["getUserBanks"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert", "showToast"]),
    next() {
      this.$refs.flickity.next();
    },
    selectDefaultCard(data) {
      if (data.primary) {
        this.showAlert({
          display: true,
          description: "This card is already your default card",
          title: "Error",
          type: "error",
        });
      } else {
        this.selectedCard = data;
        this.displaySelectedCardModal = true;
      }
    },
    previous() {
      this.$refs.flickity.previous();
    },

    getUserCards() {
      this.cardLoading = true;
      this.getCards()
        .then(() => {
          this.cardLoading = false;
          this.$refs.flickity.resize();
        })
        .catch(() => {
          this.cardLoading = false;
        });
    },

    handleCloseAddbank() {
      this.showAddBank = false;
    },

    handleLinkBank() {
      this.showAddBank = true;
    },

    handleAddBankSuccess() {
      this.showAddBank = false;
      this.setLoading = true;
      this.getAllUserBanks()
        .then(() => {
          if(this.userBanks.length === 0){
            this.getDashboardData().then(() => {
              this.setLoading(false)
            })
          }else{
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading = false;
        });
    },

    getAllUserBanks() {
      this.bankLoading = true;
      this.getUserBanks()
        .then(() => {
          this.bankLoading = false;
        })
        .catch(() => {
          this.bankLoading = false;
        });
    },

    handleCardClose() {
      this.getUserCards();
    },

    setAsDefaultCard() {
      this.defaultCardLoading = true;
      SET_DEFAULT_CARD(this.selectedCard?.id)
        .then(() => {
          this.defaultCardLoading = false;
          this.showAlert({
            display: true,
            title: "Success",
            description: "You changes have been successfully updated",
            type: "success",
          });
          this.displaySelectedCardModal = false;
          this.getUserCards();
        })
        .catch(() => {
          this.defaultCardLoading = false;
        });
    },
  },

  beforeDestroy() {
    this.hideCarousel = false;
  },
};
</script>
  <style scoped>
@import "~@/assets/styles/animation.css";

.hide-carousel {
  display: none;
}

.container-body {
  overflow: hidden;
}

.first-card {
  background-color: #b692ef;
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/v1632562840/customer/Dialog_fwwgw5.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper {
  height: 12rem;
  /* overflow: hidden; */
}

.carousel-cell {
  height: 12rem;
  border-radius: 16px;
  padding: 1rem;
  width: 20rem;
  /* background-color: red; */
  margin-right: 10px;
}

.add-card {
  background-color: white;
  border: 1px dashed #bcace3;
  box-shadow: 0px 5px 20px rgba(97, 55, 187, 0.06);
  border-radius: 16px;
  display: grid;
  place-items: center;
}

.button-round {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
  background-color: #f6f3ff;
  cursor: pointer;
}

.default-card-container {
  background: rgba(243, 243, 246, 0.5);
  border-radius: 4px;
}

.default-banner {
  background: #2c994b;
  color: white;
  padding: 0.3rem;
  width: 100%;
  position: absolute;
  transform: rotate(30deg);
  right: -5rem;
  top: 1.5rem;
}

@media (max-width: 768px) {
  .carousel-cell {
    width: 80%;
    height: 9rem;
  }
}

/* @import url("node_modules/@egjs/flicking/dist/flicking-inline.css"); */
</style>
  