var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "accounts mt-4" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.loading
              ? _c(
                  "div",
                  { key: "loading" },
                  _vm._l(1, function (i) {
                    return _c(
                      "Card",
                      {
                        key: i,
                        staticClass: "mb-4",
                        attrs: { background: "lightPurple" },
                      },
                      [
                        _c("Skeleton", {
                          attrs: { width: "50%", height: "1.5rem" },
                        }),
                        _c("Skeleton", {
                          attrs: { width: "30%", height: "1rem" },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "div",
                  { key: "data\n    " },
                  [
                    _vm.noCashoutAccount
                      ? _c(
                          "p",
                          { staticClass: "text-center mt-8 text-black" },
                          [
                            _vm._v(
                              " You have not added any cashout account yet "
                            ),
                          ]
                        )
                      : _c(
                          "Card",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              background: "bg-layoutGrey-2",
                              p: "p-3 md:p-6",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md:flex justify-between items-center",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "account-number font-semibold text-base md:text-lg",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.accountNumber) +
                                          " - " +
                                          _vm._s(_vm.bankName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mt-2 text-lightGrey-2 text-sm opacity-70 capitalize",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.accountName) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                  ],
                  1
                ),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-end mt-3" },
            [
              _c("SecondaryButton", {
                attrs: {
                  text: _vm.noCashoutAccount
                    ? "Add account"
                    : "Edit account info",
                },
                on: {
                  click: function ($event) {
                    return _vm.changeAccount()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: _vm.noCashoutAccount ? "Add account" : "Change account",
            display: _vm.showCashoutModal,
          },
          on: {
            close: function ($event) {
              ;(_vm.showCashoutModal = false), _vm.getCashoutAccountInfo()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("Select", {
                staticClass: "mt-2",
                attrs: {
                  width: "w-full",
                  label: "prettyName",
                  placeholder: "Bank",
                  disabled: _vm.disabled,
                  options: _vm.allBanks,
                  reduce: function (bank) {
                    return bank.id
                  },
                  errorText: "Please select a bank",
                  validation: _vm.rules.bank,
                },
                on: {
                  input: _vm.bankChanged,
                  valid: function ($event) {
                    _vm.valid.bank = $event
                  },
                },
                model: {
                  value: _vm.userCashoutAccount.bank.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.userCashoutAccount.bank, "id", $$v)
                  },
                  expression: "userCashoutAccount.bank.id",
                },
              }),
              _c("Input", {
                staticClass: "mt-11",
                attrs: {
                  width: "w-full",
                  placeholder: "Account number",
                  maxlength: "10",
                  disabled: _vm.disabled,
                  errorText: "Please enter a valid account number",
                  validation: _vm.rules.number,
                  number: "",
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.number = $event
                  },
                },
                model: {
                  value: _vm.userCashoutAccount.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.userCashoutAccount, "number", $$v)
                  },
                  expression: "userCashoutAccount.number",
                },
              }),
              _c("Input", {
                staticClass: "mt-11",
                attrs: {
                  width: "w-full",
                  placeholder: "Account name",
                  disabled: "",
                  errorText: "Must be a valid account name",
                  validation: _vm.rules.name,
                },
                on: {
                  valid: function ($event) {
                    _vm.valid.name = $event
                  },
                },
                model: {
                  value: _vm.userCashoutAccount.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.userCashoutAccount, "name", $$v)
                  },
                  expression: "userCashoutAccount.name",
                },
              }),
              _c("Button", {
                staticClass: "my-8",
                attrs: {
                  text: "Continue",
                  width: "w-full",
                  loading: _vm.updateLoading,
                  disabled: _vm.disableButton,
                },
                on: { click: _vm.createCashout },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showOtpModal },
          on: {
            close: function ($event) {
              ;(_vm.showOtpModal = false), _vm.getCashoutAccountInfo()
            },
          },
        },
        [
          _c("h5", { staticClass: "text-black text-lg font-semibold" }, [
            _vm._v(
              " We have sent a code to your email (" + _vm._s(_vm.email) + ") "
            ),
          ]),
          _c("p", { staticClass: "mt-4 text-sm" }, [
            _vm._v(" Just to be sure you own the bank account provided. "),
          ]),
          _c("Input", {
            staticClass: "mt-8",
            attrs: {
              placeholder: "Enter OTP",
              width: "w-full",
              type: "number",
              errorText: "OTP must be 6 characters",
              validation: _vm.otp.otpValid,
            },
            on: {
              valid: function ($event) {
                _vm.otpValid = $event
              },
            },
            model: {
              value: _vm.userCashoutAccount.otp,
              callback: function ($$v) {
                _vm.$set(_vm.userCashoutAccount, "otp", $$v)
              },
              expression: "userCashoutAccount.otp",
            },
          }),
          _c("Button", {
            staticClass: "my-8",
            attrs: {
              text: "Continue",
              width: "w-full",
              loading: _vm.verifyLoading,
              disabled: _vm.disableOtpButton,
            },
            on: { click: _vm.verifyOtp },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-8" }, [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("p", { staticClass: "font-semibold text-lg text-lightPurple-2" }, [
          _vm._v(" My cashout account "),
        ]),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }