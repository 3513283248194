<template>
  <div>
    <div class="mt-8">
      <div class="flex items-center justify-between">
        <p class="font-semibold text-lg text-lightPurple-2">
          My cashout account
        </p>
        <div>
          <!-- <SecondaryButton
            :text="noCashoutAccount ? 'Add account' : 'Change account'"
            @click="changeAccount()"
          /> -->
        </div>
      </div>
    </div>
    <div class="accounts mt-4">
      <transition name="fade" mode="out-in">
        <div v-if="loading" key="loading">
          <Card background="lightPurple" v-for="i in 1" :key="i" class="mb-4">
            <Skeleton width="50%" height="1.5rem" />
            <Skeleton width="30%" height="1rem" />
          </Card>
        </div>
        <div
          v-else
          key="data
      "
        >
          <p class="text-center mt-8 text-black" v-if="noCashoutAccount">
            You have not added any cashout account yet
          </p>
          <Card background="bg-layoutGrey-2" class="mb-4" v-else p="p-3 md:p-6">
            <div class="md:flex justify-between items-center">
              <div>
                <p class="account-number font-semibold text-base md:text-lg">
                  {{ accountNumber }} -
                  {{ bankName }}
                </p>
                <p class="mt-2 text-lightGrey-2 text-sm opacity-70 capitalize">
                  {{ accountName }}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </transition>
      <div class="flex justify-end mt-3">
        <SecondaryButton
          :text="noCashoutAccount ? 'Add account' : 'Edit account info'"
          @click="changeAccount()"
        />
      </div>
    </div>
    <Modal
      :title="noCashoutAccount ? 'Add account' : 'Change account'"
      :display="showCashoutModal"
      @close="(showCashoutModal = false), getCashoutAccountInfo()"
    >
      <div class="mt-4">
        <Select
          class="mt-2"
          width="w-full"
          label="prettyName"
          placeholder="Bank"
          :disabled="disabled"
          @input="bankChanged"
          :options="allBanks"
          :reduce="(bank) => bank.id"
          v-model="userCashoutAccount.bank.id"
          errorText="Please select a bank"
          :validation="rules.bank"
          @valid="valid.bank = $event"
        />

        <Input
          width="w-full"
          placeholder="Account number"
          maxlength="10"
          class="mt-11"
          :disabled="disabled"
          v-model="userCashoutAccount.number"
          errorText="Please enter a valid account number"
          :validation="rules.number"
          @valid="valid.number = $event"
          number
        />

        <Input
          width="w-full"
          placeholder="Account name"
          class="mt-11"
          v-model="userCashoutAccount.name"
          disabled
          errorText="Must be a valid account name"
          :validation="rules.name"
          @valid="valid.name = $event"
        />
        <Button
          text="Continue"
          width="w-full"
          class="my-8"
          @click="createCashout"
          :loading="updateLoading"
          :disabled="disableButton"
        />
      </div>
    </Modal>
    <Modal
      :display="showOtpModal"
      @close="(showOtpModal = false), getCashoutAccountInfo()"
    >
      <h5 class="text-black text-lg font-semibold">
        We have sent a code to your email ({{ email }})
      </h5>

      <p class="mt-4 text-sm">
        Just to be sure you own the bank account provided.
      </p>
      <Input
        class="mt-8"
        placeholder="Enter OTP"
        width="w-full"
        v-model="userCashoutAccount.otp"
        type="number"
        errorText="OTP must be 6 characters"
        :validation="otp.otpValid"
        @valid="otpValid = $event"
      />

      <Button
        text="Continue"
        width="w-full"
        class="my-8"
        @click="verifyOtp"
        :loading="verifyLoading"
        :disabled="disableOtpButton"
      />
    </Modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { Modal } from "@/UI/Modals";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { Select, Input } from "@/UI/Input";

  export default {
    components: {
      Modal,
      Button,
      SecondaryButton,
      Select,
      Input,
    },
    data: () => ({
      showCashoutModal: false,
      loading: false,
      valid: {
        number: false,
        bank: false,
        name: false,
      },
      otpValid: false,
      verifyLoading: false,
      updateLoading: false,
      showOtpModal: false,
      accountNumber: "",
      accountName: "",
      bankName: "",
      userCashoutAccount: {
        bank: {},
        number: "",
        name: "",
        otp: "",
      },
      disabled: true,
      requestMethod: "post",
      noCashoutAccount: false,
    }),
    computed: {
      ...mapState({
        // cashout: (state) => state?.userInfo?.userCashoutAccount,
        allBanks: (state) => state?.settings?.allBanks,
        customerId: (state) => state?.auth?.user?.id,
        email: (state) => state?.auth?.user?.email,
      }),
      getAccountName() {
        if (
          this.userCashoutAccount?.number?.length === 10 &&
          this.userCashoutAccount?.bank.id !== null
        ) {
          return true;
        } else return false;
      },
      rules() {
        return {
          number: this.userCashoutAccount?.number?.length === 10,
          bank: this.userCashoutAccount?.bank.id?.length !== 0,
          name:
            this.userCashoutAccount?.name !== null &&
            this.userCashoutAccount?.name !== "",
        };
      },
      otp() {
        return {
          otpValid: this.userCashoutAccount.otp?.length === 6,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
      disableOtpButton() {
        return Object.values(this.otp).includes(false);
      },
    },
    watch: {
      getAccountName() {
        if (this.getAccountName & !this.disabled) {
          this.getBankDetails();
        }
      },
      customerId() {
        if (this.customerId) {
          this.getCashoutAccountInfo();
        }
      },
    },
    mounted() {
      this.getCashoutAccountInfo();
      this.getAllBanks();
    },
    methods: {
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("userInfo", ["getCashoutAccount"]),
      ...mapActions("notification", ["showAlert"]),
      ...mapActions("settings", [
        "getAllBanks",
        "createCashoutAccount",
        "accountLookup",
        "verifyCashoutAccount",
      ]),
      changeAccount() {
        this.disabled = false;
        this.showCashoutModal = true;
      },
      getCashoutAccountInfo() {
        this.requestMethod = "post";
        this.loading = true;
        this.getCashoutAccount(this.customerId)
          .then((res) => {
            this.requestMethod = "put";
            this.bankName = res?.data?.data?.bank?.prettyName;
            this.accountName = res?.data?.data?.name;
            this.accountNumber = res?.data?.data?.number;

            this.userCashoutAccount = res.data.data;
            this.loading = false;
            this.noCashoutAccount = false;
          })
          .catch((err) => {
            if (
              err.response.data.message === "Cashout bank account not found"
            ) {
              this.noCashoutAccount = true;
              this.userCashoutAccount = {
                bank: {},
                number: "",
                name: "",
                otp: "",
              };
            }
            this.loading = false;
          });
      },
      bankChanged() {
        this.userCashoutAccount.number = "";
        this.userCashoutAccount.name = "";
      },
      getBankDetails() {
        this.setLoading(true);
        this.accountLookup({
          accountNumber: this.userCashoutAccount.number,
          bankId: this.userCashoutAccount.bank.id,
        })
          .then((res) => {
            this.setLoading(false);
            this.userCashoutAccount.name = res.data.data.accountName;
          })
          .catch(() => {
            this.setLoading(false);
            this.userCashoutAccount.name = "";
          });
      },
      createCashout() {
        this.updateLoading = true;
        this.createCashoutAccount({
          method: this.requestMethod,
          data: {
            accountNumber: this.userCashoutAccount.number,
            accountName: this.userCashoutAccount.name,
            bankId: this.userCashoutAccount.bank.id,
          },
        })
          .then((res) => {
            this.updateLoading = false;
            this.showCashoutModal = false;
            if (this.requestMethod === "post") {
              this.showAlert({
                display: true,
                type: "success",
                description: `Cashout Account details added succesfully`,
              });
              this.getCashoutAccountInfo();
            } else if (
              res.data.data.cashoutBankAccount.status ===
              "PENDING_OTP_VERIFICATION"
            ) {
              this.showOtpModal = true;
            }
          })
          .catch(() => {
            this.updateLoading = false;
          });
      },
      verifyOtp() {
        this.verifyLoading = true;
        this.verifyCashoutAccount({
          otp: this.userCashoutAccount.otp,
        })
          .then(() => {
            this.verifyLoading = false;
            this.showOtpModal = false;
            this.showAlert({
              display: true,
              type: "success",
              description: `Cashout Account details updated succesfully`,
            });
            this.getCashoutAccountInfo();
            this.bank.otp = "";
            this.disabled = true;
          })
          .catch(() => {
            this.verifyLoading = false;
          });
      },
    },
  };
</script>

<style></style>
