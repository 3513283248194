var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "accounts mt-4" }, [
      _vm.bankLoading
        ? _c(
            "div",
            _vm._l(3, function (i) {
              return _c(
                "Card",
                {
                  key: i,
                  staticClass: "mb-4",
                  attrs: { background: "lightPurple" },
                },
                [
                  _c("Skeleton", { attrs: { width: "50%", height: "1.5rem" } }),
                  _c("Skeleton", { attrs: { width: "30%", height: "1rem" } }),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            [
              _vm.userBanks.length < 1 || !_vm.anyAvailableBanks
                ? _c("p", { staticClass: "text-center mt-8 text-black" }, [
                    _vm._v(" You have not added any bank account yet "),
                  ])
                : _vm._l(_vm.userBanks, function (bank) {
                    return _c(
                      "div",
                      { key: bank.id },
                      [
                        bank.dataStatus === "AVAILABLE"
                          ? _c(
                              "Card",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  background: "bg-layoutGrey",
                                  p: "p-3 md:p-6",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md:flex justify-between items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "account-number font-semibold text-base md:text-lg",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(bank.accountNumber) +
                                              " - " +
                                              _vm._s(bank.bankName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mt-2 text-lightGrey-2 text-sm opacity-70",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.user.firstname
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  _vm.user.lastname
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    bank.accountStatementSource
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-lightGrey-2 text-sm mt-4 md:mt-0",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("capitalize")(
                                                    bank.accountStatementSource.replace(
                                                      /_/g,
                                                      " "
                                                    )
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
              _c(
                "div",
                { staticClass: "flex justify-end mt-3" },
                [
                  _c("SecondaryButton", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "This button is currently disabled because we’re revising and <br/> improving our installment payment plans",
                        expression:
                          "`This button is currently disabled because we’re revising and <br/> improving our installment payment plans`",
                      },
                    ],
                    attrs: { disabled: !_vm.enableCredit, text: "Add account" },
                    on: {
                      click: function ($event) {
                        _vm.enableCredit && _vm.$emit("linkBank")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-36" }, [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("div", [
          _c("p", { staticClass: "font-semibold text-lg text-lightPurple-2" }, [
            _vm._v("Bank accounts"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }