var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { staticClass: "container-body" },
    [
      _c("div", [
        _c("div", { staticClass: "flex items-center justify-between" }, [
          _c("p", { staticClass: "text-lightPurple-2 font-semibold" }, [
            _vm._v("My Cards"),
          ]),
          _c("div", { staticClass: "controls flex items-center" }, [
            _c(
              "div",
              {
                staticClass: "button-round p-1 mr-3",
                on: { click: _vm.previous },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://res.cloudinary.com/zillaafrica/image/upload/v1624012023/customer/Vector_15_c0u4go.svg",
                    alt: "previous",
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "button-round p-1", on: { click: _vm.next } },
              [
                _c("img", {
                  attrs: {
                    src: "https://res.cloudinary.com/zillaafrica/image/upload/v1624012221/customer/Vector_15_1_pkuwqn.svg",
                    alt: "next",
                  },
                }),
              ]
            ),
          ]),
        ]),
        _vm.cardLoading
          ? _c(
              "div",
              { key: "none", staticClass: "wrapper mt-6" },
              [
                _c(
                  "Flickity",
                  { attrs: { options: _vm.flickityOptions } },
                  _vm._l(2, function (i) {
                    return _c("Skeleton", {
                      key: i,
                      staticClass: "mr-2",
                      attrs: { height: "9rem", width: "18rem" },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { key: "around", staticClass: "wrapper mt-6" },
              [
                _vm.userCards.length === 0
                  ? _c(
                      "AddCard",
                      {
                        attrs: { startAddCard: _vm.initiateAddCard },
                        on: {
                          stopAddCard: function ($event) {
                            _vm.initiateAddCard = false
                          },
                          close: _vm.handleCardClose,
                        },
                      },
                      [
                        [
                          _c(
                            "Flickity",
                            {
                              ref: "flickity",
                              attrs: { options: _vm.flickityOptions },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "carousel-cell add-card cursor-pointer",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center",
                                      on: {
                                        click: function ($event) {
                                          _vm.initiateAddCard = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-lightPurple-2 text-lg md:text-2xl",
                                        },
                                        [_vm._v("+")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-semibold text-lightPurple-2",
                                        },
                                        [_vm._v("Add a Card")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-xs text-lightPurple-2 mt-2",
                                        },
                                        [
                                          _vm._v(
                                            " Kindly make sure the card you intend to add is yours and tied to your account "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                      ],
                      2
                    )
                  : _c(
                      "Flickity",
                      {
                        ref: "flickity",
                        attrs: { options: _vm.flickityOptions },
                      },
                      _vm._l(_vm.cardList, function (card) {
                        return _c(
                          "div",
                          {
                            key: card.id,
                            staticClass:
                              "carousel-cell overflow-hidden first-card relative grid p-2",
                            on: {
                              click: function ($event) {
                                return _vm.selectDefaultCard(card)
                              },
                            },
                          },
                          [
                            card.primary
                              ? _c(
                                  "div",
                                  { staticClass: "default-banner text-center" },
                                  [_c("p", [_vm._v("DEFAULT")])]
                                )
                              : _vm._e(),
                            card.cardType
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    card.cardType.toLowerCase().trim() ===
                                    "visa"
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "53",
                                              height: "17",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M27.226 5.435c-.03 2.368 2.11 3.69 3.723 4.475 1.657.806 2.213 1.323 2.207 2.044-.013 1.103-1.322 1.59-2.547 1.61-2.137.033-3.38-.578-4.368-1.04l-.77 3.603c.992.457 2.827.856 4.73.873 4.468 0 7.39-2.205 7.406-5.624.018-4.34-6.002-4.58-5.96-6.52.013-.588.575-1.215 1.805-1.375.608-.08 2.288-.142 4.193.735L38.393.73C37.37.357 36.052 0 34.413 0c-4.205 0-7.163 2.235-7.187 5.435ZM45.578.3c-.816 0-1.504.476-1.81 1.207l-6.382 15.237h4.464l.889-2.455h5.455l.515 2.455h3.935L49.211.3h-3.633Zm.624 4.442 1.289 6.175h-3.529l2.24-6.175ZM21.814.3l-3.52 16.444h4.255L26.066.3h-4.252ZM15.52.3l-4.428 11.192-1.79-9.516C9.091.914 8.261.3 7.34.3H.1L0 .778c1.486.322 3.174.842 4.197 1.399.626.34.805.637 1.01 1.445L8.6 16.744h4.495L19.988.3H15.52Z",
                                                fill: "url(#a)",
                                              },
                                            }),
                                            _c(
                                              "defs",
                                              [
                                                _c(
                                                  "linearGradient",
                                                  {
                                                    attrs: {
                                                      id: "a",
                                                      x1: "24.203",
                                                      y1: "17.341",
                                                      x2: "24.695",
                                                      y2: "-.118",
                                                      gradientUnits:
                                                        "userSpaceOnUse",
                                                    },
                                                  },
                                                  [
                                                    _c("stop", {
                                                      attrs: {
                                                        "stop-color": "#fff",
                                                      },
                                                    }),
                                                    _c("stop", {
                                                      attrs: {
                                                        offset: "1",
                                                        "stop-color": "#E3E3E3",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    card.cardType.toLowerCase().trim() ===
                                    "mastercard"
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "32",
                                              height: "19",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M19.766 2.032h-8.413v14.936h8.413V2.032Z",
                                                fill: "#FF5F00",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M11.886 9.5c0-3.035 1.442-5.726 3.659-7.468A9.63 9.63 0 0 0 9.615 0C4.3 0 0 4.249 0 9.5S4.3 19 9.615 19a9.63 9.63 0 0 0 5.93-2.032 9.445 9.445 0 0 1-3.66-7.468Z",
                                                fill: "#EB001B",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M31.117 9.5c0 5.251-4.3 9.5-9.615 9.5a9.63 9.63 0 0 1-5.93-2.032 9.405 9.405 0 0 0 3.66-7.468c0-3.035-1.443-5.726-3.66-7.468A9.63 9.63 0 0 1 21.502 0c5.315 0 9.615 4.275 9.615 9.5Z",
                                                fill: "#F79E1B",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    card.cardType.toLowerCase().trim() ===
                                    "verve"
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "70",
                                              height: "25",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d: "M24.23 12.115c0 6.691-5.423 12.115-12.114 12.115C5.424 24.23 0 18.806 0 12.115 0 5.425 5.424 0 12.116 0c6.69 0 12.115 5.424 12.115 12.115Z",
                                                fill: "#ED342B",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d: "M12.115 17.305C9.405 11.162 7.418 5.424 7.418 5.424H3.264S5.793 12.789 10.49 22h3.251c4.697-9.21 7.226-16.576 7.226-16.576h-4.155s-1.987 5.738-4.697 11.881Z",
                                                fill: "#FEFEFE",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d: "M64.292 11.483c-2.349 0-2.53 2.53-2.53 2.53h5.058s-.18-2.53-2.528-2.53Zm5.599 5.058h-8.128s.18 2.71 3.793 2.71c1.806 0 3.612-.543 3.612-.543l.362 2.89s-1.806.723-4.335.723c-3.613 0-6.864-1.807-6.864-6.864 0-3.974 2.529-6.503 6.141-6.503 5.419 0 5.78 5.42 5.419 7.587Zm-27.639-4.268.56-2.983s-4.32-1.31-7.83 1.118v11.93h3.729v-9.693c1.49-1.118 3.541-.372 3.541-.372Zm-15.539-.79c-2.348 0-2.528 2.53-2.528 2.53h5.057s-.18-2.53-2.529-2.53Zm5.6 5.058h-8.128s.18 2.71 3.793 2.71c1.806 0 3.612-.543 3.612-.543l.362 2.89s-1.807.723-4.336.723c-3.612 0-6.864-1.807-6.864-6.864 0-3.974 2.53-6.503 6.142-6.503 5.419 0 5.78 5.42 5.419 7.587Zm19.037 1.238c-1.893-4.544-2.84-8.823-2.84-8.823h-3.784s1.892 7.308 5.11 13.363h3.027c3.218-6.055 5.111-13.361 5.111-13.361H54.19s-.946 4.277-2.84 8.82Z",
                                                fill: "#fff",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-end h-full justify-between",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm md:text-base text-white font-semibold",
                                  },
                                  [_vm._v(" " + _vm._s(card.maskedPan) + " ")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm md:text-base text-white font-semibold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(card.expireMonth) +
                                        "/" +
                                        _vm._s(card.expireYear) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                _c(
                  "div",
                  {
                    staticClass:
                      "default-card-container flex items-center p-4 my-3",
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "mr-3",
                      attrs: {
                        color: "#0EA2F5",
                        type: "mdi",
                        path: _vm.svgPath.warning,
                      },
                    }),
                    _c("p", { staticClass: "text-grey text-xs mr-3" }, [
                      _vm._v(
                        " Choose a default card for payment by clicking on the card. "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm.userCards.length > 0
                  ? _c(
                      "div",
                      { staticClass: "flex justify-end mt-4" },
                      [_c("AddCard", { on: { close: _vm.handleCardClose } })],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
      _c("BankAccounts", {
        attrs: { bankLoading: _vm.bankLoading },
        on: { linkBank: _vm.handleLinkBank },
      }),
      _c(
        "LargeModal",
        {
          attrs: {
            display: _vm.showAddBank,
            padding: "px-6 pt-6",
            background: "white",
          },
          on: {
            close: function ($event) {
              _vm.showAddBank = false
            },
          },
        },
        [
          _c("AddBank", {
            on: {
              bankAdded: _vm.handleAddBankSuccess,
              close: _vm.handleCloseAddbank,
            },
          }),
        ],
        1
      ),
      _c("CashoutAccount"),
      _c(
        "Modal",
        {
          attrs: { display: _vm.displaySelectedCardModal },
          on: {
            close: function ($event) {
              _vm.displaySelectedCardModal = false
            },
          },
        },
        [
          _c("p", { staticClass: "text-lg primary-text font-bold" }, [
            _vm._v(" Choose as default card for payments "),
          ]),
          _c("p", { staticClass: "text-grey text-sm mt-4" }, [
            _vm._v(
              " You are about to choose (**** " +
                _vm._s(_vm.selectedCard.last4Digits) +
                ") as the card that should be charged for payments. You can always change this anytime. "
            ),
          ]),
          _c("Button", {
            staticClass: "mt-6",
            attrs: {
              width: "w-full",
              text: "Choose as default card",
              loading: _vm.defaultCardLoading,
            },
            on: { click: _vm.setAsDefaultCard },
          }),
          _c(
            "p",
            {
              staticClass: "text-center mt-4 font-semibold text-brandPurple",
              on: {
                click: function ($event) {
                  _vm.displaySelectedCardModal = false
                },
              },
            },
            [_vm._v(" Close ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }