<template>
  <div>
    <div class="mt-36">
      <div class="flex items-center justify-between">
        <div>
          <p class="font-semibold text-lg text-lightPurple-2">Bank accounts</p>
        </div>
      </div>
    </div>
    <!-- bank section -->
    <div class="accounts mt-4">
      <div v-if="bankLoading">
        <Card background="lightPurple" v-for="i in 3" :key="i" class="mb-4">
          <Skeleton width="50%" height="1.5rem" />
          <Skeleton width="30%" height="1rem" />
        </Card>
      </div>
      <div v-else>
        <p
          class="text-center mt-8 text-black"
          v-if="userBanks.length < 1 || !anyAvailableBanks"
        >
          You have not added any bank account yet
        </p>
        <div v-else v-for="bank in userBanks" :key="bank.id">
          <Card
            background="bg-layoutGrey"
            class="mb-4"
            v-if="bank.dataStatus === 'AVAILABLE'"
            p="p-3 md:p-6"
          >
            <div class="md:flex justify-between items-center">
              <div>
                <p class="account-number font-semibold text-base md:text-lg">
                  {{ bank.accountNumber }} - {{ bank.bankName }}
                </p>
                <p class="mt-2 text-lightGrey-2 text-sm opacity-70">
                  {{ user.firstname | capitalize }}
                  {{ user.lastname | capitalize }}
                </p>
              </div>
              <p
                v-if="bank.accountStatementSource"
                class="text-lightGrey-2 text-sm mt-4 md:mt-0"
              >
                {{
                  bank.accountStatementSource.replace(/_/g, " ") | capitalize
                }}
              </p>
            </div>
          </Card>
        </div>
        <div class="flex justify-end mt-3">
          <SecondaryButton :disabled="!enableCredit" text="Add account" @click="enableCredit && $emit('linkBank')" v-tooltip="`This button is currently disabled because we’re revising and <br/> improving our installment payment plans`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { SecondaryButton } from "@/UI/Button";
export default {
  components: {
    SecondaryButton,
  },

  props: {
    bankLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  computed: {
    ...mapState({
      userBanks: (state) => state?.userInfo?.userBanks,
      user: (state) => state?.auth?.user,
      enableCredit:(state) => state?.auth?.enableCredit

    }),

    anyAvailableBanks() {
      if (this.userBanks.some((e) => e.dataStatus === "AVAILABLE")) {
        return true;
      } else return false;
    },
  },
};
</script>

<style>
</style>